<template>
  <div>
    <b-overlay
      :show="showOver"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <p id="cancel-label">กรุณารอสักครู่...</p>
        </div>
      </template>

      <div class="mb-1">
        <b-button
          class="btn btn-gradient-primary"
          :to="{ name: 'admin-employee-add' }"
        >
          <i class="fa fa-plus" /> {{ $t("addUser") }}
        </b-button>
      </div>

      <div class="card">
        <b-form-group
          label-cols-sm="12"
          label-cols="12"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="p-2 justify-content-end"
        >
          <!-- <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group> -->

          <div class="d-flex flex-wrap align-items-center justify-content-end">
          
            <b-form-select class="mb-lg-0 mb-md-2 mb-sm-2 mx-lg-2 mx-md-0 mx-sm-0 col-lg-2 col-sm-12 col-md-12" size="sm" v-model="roleitem" 
            :options="rolelist" 
            @change="GetData()"
              />
            <div class="anan-input__inner anan-input__inner--normal col-lg-2 col-sm-12 col-md-12">
              <input
                v-model="search"
                type="text"
                :placeholder="$t('search')"
                class="anan-input__input"
                @keyup.enter="GetData()"
              />
              <div class="anan-input__suffix">
                <button
                  type="button"
                  class="anan-button anan-button--normal anan-button--primary"
                  @click="GetData()"
                >
                  <i class="fal fa-search" />
                </button>
              </div>
            </div>
          </div>
        </b-form-group>
        <div class="pl-2 pr-2 pt-0">
          <div class="table-container">
            <b-table
              striped
              hover
              :per-page="perPage"
              :items="items"
              :fields="fieldsMultiLang"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              show-empty
              class="table-mobile"
            >
              <template #empty>
                <div class="text-center">
                  <img
                    src="@/assets/images/anan-img/empty/empty.png"
                    alt="empty"
                    width="180px"
                  />
                  <p>No Data</p>
                </div>
              </template>
              <template #cell(userID)="data">
                <b-link
                  :to="{
                    name: 'admin-employee-edit',
                    params: { id: data.item.id },
                  }"
                  class="mb-0"
                >
                  {{ data.item.itemnumber_text }}
                </b-link>
              </template>

              <template #cell(fullname)="data">
                <b-link
                  :to="{
                    name: 'admin-employee-edit',
                    params: { id: data.item.id },
                  }"
                  class="mb-0"
                >
                  {{ data.item.fullname }}
                </b-link>
              </template>
              <template #cell(role)="data">
                <b-link
                  :to="{
                    name: 'admin-employee-edit',
                    params: { id: data.item.id },
                  }"
                  class="mb-0"
                >
                  {{
                    data.item.role === 0
                      ? "ลูกค้า"
                      : data.item.role === 1
                      ? "แอดมิน"
                      : "พนักงาน"
                  }}
                </b-link>
              </template>
              <template #cell(roles)="data">
                {{ CheckModule(data.item.module_id) }}
              </template>
              <template #cell(created_at)="data">
                {{ time(data.item.created_at) }}
              </template>
              <template #cell(group_name)="data">
                {{ data.item.group_name ? data.item.group_name : "-" }}
              </template>
              <template #cell(actions)="data">
                <feather-icon
                  icon="Edit3Icon"
                  class="mr-50 text-primary cursor-pointer"
                  @click="EditEmployee(data.item)"
                />
                <feather-icon
                  icon="Trash2Icon"
                  class="mr-50 text-danger cursor-pointer"
                  @click="DeleteData(data.item, subrole)"
                />
              </template>
            </b-table>
          </div>
        </div>
        <div class="demo-spacing-0 d-flex justify-content-end my-1 mr-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="GetData()"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  // BRow,
  // BCol,
  BTable,
  BPagination,
  // BAvatar,
  BInputGroup,
  BFormInput,
  BButton,
  BFormGroup,
  BInputGroupAppend,
  BOverlay,
  // BIconController,
  BBadge,
  BLink,
  BFormSelect,
} from "bootstrap-vue";
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import { required } from '@validations'
import vSelect from "vue-select";
import moment from "moment-timezone";

export default {
  components: {
    // ValidationProvider,
    // ValidationObserver,
    // BRow,
    // BCol,
    BTable,
    BPagination,
    // BAvatar,
    BInputGroup,
    BFormInput,
    BButton,
    BFormGroup,
    BInputGroupAppend,
    BBadge,
    // BForm,
    // BModal,
    BOverlay,
    // BIconController,
    vSelect,
    BLink,
    BFormSelect,
  },
  data() {
    return {
      employee_id: null,
      name: null,
      surname: null,
      username: null,
      role: null,
      tel: null,
      password: null,
      id: null,

      show: true,
      // required,
      perPage: 10,
      pageOptions: [10, 20, 30, 40, 50, 100],
      totalRows: 0,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },

      // fields: [
      //   { key: 'index', label: 'no.', thStyle: { width: '10%' } },
      //   {
      //     key: 'roles', label: 'เลเวล', sortable: true, thStyle: { width: '10%' },
      //   },
      //   { key: 'fullname', label: 'ชื่อ - นามสกุล', thStyle: { width: '15%' } },
      //   {
      //     key: 'username', label: 'ยูสเซอร์เนม', sortable: true, thStyle: { width: '15%' },
      //   },
      //   {
      //     key: 'tel', label: 'เบอร์โทร', sortable: true, thStyle: { width: '15%' },
      //   },
      //   {
      //     key: 'tel', label: 'Tel', sortable: true, thStyle: { width: '15%' },
      //   },
      //   {
      //     key: 'actions', label: 'actions', sortable: true, thStyle: { width: '15%' },
      //   },
      // ],
      items: [],
      roleitem: null,
      rolelist: [
        // employee
        { text: 'ทั้งหมด', value: null },
        { text: 'ลูกค้า', value: 0 },
        { text: 'แอดมิน', value: 1 }, 
        { text: 'พนักงาน', value: 2 },
      ],
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
      subrole: {},
      search: "",
      ModuleList: [],
    };
  },
  computed: {
    fieldsMultiLang() {
      return [
        { key: "userID", label: "หมายเลข", thStyle: { width: "1%" } },
        {
          key: "username",
          label: "รหัสลูกค้า",
          sortable: false,
          thStyle: { width: "10%" },
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "role",
          label: "เลเวล",
          sortable: false,
          thStyle: { width: "5%" },
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "created_at",
          label: "วันที่สร้าง",
          thStyle: { width: "5%" },
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "fullname",
          label: `${this.$t("firstName")} - ${this.$t("lastName")}`,
          thStyle: { width: "10%" },
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "email",
          label: "อีเมล์",
          thStyle: { width: "1%" },
          thClass: "text-center",
        },
        {
          key: "tel",
          label: this.$t("phoneNumber"),
          sortable: false,
          thStyle: { width: "10%" },
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "roles",
          label: "สิทธิ์การเข้าใช้งาน",
          sortable: false,
          thStyle: { width: "5%" },
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "group_name",
          label: this.$t("userGroup"),
          sortable: false,
          thStyle: { width: "10%" },
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "actions",
          label: this.$t("actions"),
          sortable: false,
          thStyle: { width: "2%" },
          thClass: "text-center",
          tdClass: "text-center",
        },
      ];
    },
    rolelist() {
      return [
        { text: "แอดมิน", value: 1 },
        { text: this.$t("customer"), value: 0 },
        { text: this.$t("key-258"), value: 2 },
      ];
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    // Set the initial number of items
    this.GetData();
    this.totalRows = this.items.length;
    this.GetModuleList();
  },
  methods: {
    time(data) {
      return moment(data).add(7, "hours").format("YYYY-MM-DD HH:mm:ss");
    },
    async GetModuleList() {
      try {
        const { data: res } = await this.$http.get("/users/ModuleList");
        this.ModuleList = res.data;
      } catch (err) {
        console.log(err);
      }
    },
    CheckModule(id) {
      if (id) {
        const data = this.ModuleList.find((ele) => ele.id === id);
        return data.name;
      }
      return "-";
    },
    //
    GetData() {
      // let index = 0
      this.show = true;
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        search: this.search,
        role: this.roleitem,
      };
      this.$http
        .get("/users", { params })
        .then((response) => {
          // console.log(response.data)
          this.show = false;
          response.data.data.map((ele) => {
            ele.group_name = null;
            ele.roles = null;
          });
          this.totalRows = response.data.total;
          this.items = response.data.data;
          if (this.items.length) {
            this.userGroupItem(this.items);
          }
        })
        .catch((error) => console.log(error));
    },
    addData() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          const formData = {
            id: this.id,
            employee_id: this.employee_id,
            name: this.name,
            surname: this.surname,
            username: this.username,
            tel: this.tel,
            role: 1,
            password: this.password,
          };
          this.$http
            .post("/employee/storeAndupdate", formData)
            .then(() => {
              this.GetData();
              if (this.id) {
                this.Success("แก้ไขข้อมูลพนักงานสำเร็จ");
              } else {
                this.Success("เพิ่มพนักงานสำเร็จ");
              }
              this.id = null;
              this.employee_id = null;
              this.name = null;
              this.surname = null;
              this.username = null;
              this.tel = null;
              this.role = null;
              this.password = null;
              this.hideModal();
            })
            .catch((error) => {
              this.SwalError(error.response.data.message);
            });
        }
      });
    },
    DeleteData(data) {
      this.$bvModal
        .msgBoxConfirm("คุณต้องการลบข้อมูลใช่หรือไม่?", {
          title: "ยืนยันการลบข้อมูล",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((result) => {
          if (result) {
            const params = {
              id: data.id,
            };
            this.$http.post("/users/user_delete", params).then(() => {
              this.GetData();
              this.$bvToast.toast("ลบข้อมูลสำเร็จ", {
                title: "สำเร็จ",
                variant: "success",
                solid: true,
                autoHideDelay: 1500,
              });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    Success(mes) {
      this.$swal({
        icon: "success",
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
    },
    SwalError(mes) {
      this.$swal({
        icon: "error",
        title: `<h3 style="color: #141414">${this.$t("key-298")}</h3>`,
        text: mes,
        customClass: {
          cancelButton: "btn btn-outline-danger",
        },
      });
    },
    addEmployee() {
      this.id = null;
      this.employee_id = null;
      this.name = null;
      this.surname = null;
      this.username = null;
      this.tel = null;
      this.role = null;
      this.password = null;
      this.$refs["my-modal"].show();
    },
    EditEmployee(data) {
      this.$router.push({
        name: "admin-employee-edit",
        params: { id: data.id },
      });
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    userGroupItem(data) {
      const uid = data.map((ele) => ele.id);
      const params = {
        uid,
      };
      this.$http.get("/users/UserGroup", { params }).then((response) => {
        response.data.data.forEach((group) => {
          const item = this.items.find((ele) => ele.id === group.uid);
          if (item) {
            item.group_name = group.group_name;
          }
        });
      });
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    toggleModal() {
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
@media only screen and (max-width: 600px) {
  .table-mobile {
    width: 1000px !important;
  }
}
</style>
